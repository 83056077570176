.sidebarDrawer {
  transition-property: width;
  transition-duration: 1s;
  transition-delay: 500ms;
  width: 46px;
  flex-shrink: 0;
  pointer-events: none;
  overflow-x: hidden;
  white-space: nowrap;
  border-radius: 4px;
  position: sticky;
  top: 0;
}

.sidebarDrawer:hover {
  width: 170px;
}

.sidebarDrawerMobile {
  transition-property: width;
  transition-duration: 1s;
  transition-delay: 500ms;
  flex-shrink: 0;
  pointer-events: none;
  overflow-x: hidden;
  white-space: nowrap;
  border-radius: 4px;
  position: sticky;
  top: 0;
}

.sidebarDrawerMobile[opening='1'] {
  animation: openDrawer 0.5s linear 500ms;
}
.sidebarDrawerMobile[closing='1'] {
  animation: closeDrawer 0.5s linear 200ms;
}

@keyframes openDrawer {
  from {
    width: 46px;
  }
  to {
    width: 170px;
  }
}
@keyframes closeDrawer {
  from {
    width: 170px;
  }
  to {
    width: 46px !important;
  }
}
